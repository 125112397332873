import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Button, Link, Stack, TextField, Typography } from "@mui/material";

import { auth } from '../firebase';
import { signInWithEmailAndPassword } from "firebase/auth";


export default function Signup() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");

  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (err) {
      let errorMessage;

      switch (err.code) {
        case 'auth/invalid-login-credentials':
          errorMessage = 'Invalid login credentials';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Email address is invalid';
          break;
        case 'auth/user-disabled':
          errorMessage = 'User has been disabled';
          break;
        case 'auth/user-not-found':
          errorMessage = 'User not found';
          break;
        case 'auth/wrong-password':
          errorMessage = 'Incorrect password';
          break;
        default:
          errorMessage = `An unknown error (${err.code}) occurred`;
          break;
      }

      setError(`Login failed: ${errorMessage}`);
    }
  };

  let alert;

  if (error) {
    alert = <Alert severity="error">{error}</Alert>;
  } else {
    alert = null;
  }

  return (
<Stack justifyContent="center" alignItems="center" spacing={2}>
    <Typography variant="h5">Login to patches.fm</Typography>
    <TextField required id="email" label="E-Mail Address" onChange={(event) => { setEmail(event.target.value) }} />
    <TextField required id="password" type="password" label="Password" onChange={(event => { setPassword(event.target.value) })} />
    {alert}
    <Typography>Don't have an account? <Link href="/signup">Sign Up</Link></Typography>
    <Typography><Link href="/forgot-password">Forgot password?</Link></Typography>
    <Button onClick={handleSubmit} variant="contained">Login</Button>
  </Stack>

    );
};